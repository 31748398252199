
import { motion } from "framer-motion"
import { useState, useEffect } from 'react'
import Select from "react-select";
import { ClipLoader } from "react-spinners"
import styles from './css/home.module.scss'

import './App.css'
import gareth from './assets/gareth-nyc.png'
import nyc from './assets/nyc.jpg'
import thumb from './assets/thumb.png'
import book from './assets/book.png'


function App() {
  const TYPES = [
    'Restaurant / Bar',
    'Social Activity',
    'Sights',
  ]
  const [time, setTime] = useState(Date.now())
  const [message, setMessage] = useState('')
  const [recommendations, setRecommendations] = useState([
    {from:'Evan', message:'Hey, I would recommend 230 Fifth.', type:'Restaurant / Bar', upvotes:3},
    {from:'John', message:'Hey, I would recommend meetups in Brooklyn.', type:'Social Activity', upvotes:8},
    {from:'Mary', message:'Hey, I would recommend Long Island.', type:'Sights', upvotes:8},
  ])


  useEffect(() => {
    setInterval(()=>{
      setTime(Date.now())
    }, 1000)
  }, [])

  const getTimeUntilArrival = ()=>{
    let date_now = time 
    let date_future = 1738501200000
    let delta = Math.abs(date_future - date_now) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60

    // what's left is seconds
    let seconds = Math.round(delta % 60)

    return({days, hours, minutes, seconds})
  }

  return(
    <div>
      <img src={nyc} className={styles.full} />
      <div className={styles.countdownWidget}>
        <div className={styles.widgetLeft}>
          <img src={gareth} className={styles.profileImage} />
          <div className={styles.widgetTitle}>Gareth's NYC Trip</div>

          <div className={styles.tripLength}>Going For 1 Month</div>

          <div className={styles.countdownTimer}>
            <div className={styles.countdownBlock}>
              <div className={styles.countdownTime}>{getTimeUntilArrival().days}</div>
              <div className={styles.countdownMeasure}>Days</div>
            </div>

            <div className={styles.countdownBlock}>
              <div className={styles.countdownTime}>{getTimeUntilArrival().hours}</div>
              <div className={styles.countdownMeasure}>Hours</div>
            </div>

            <div className={styles.countdownBlock}>
              <div className={styles.countdownTime}>{getTimeUntilArrival().minutes}</div>
              <div className={styles.countdownMeasure}>Minutes</div>
            </div>

            <div className={styles.countdownBlock}>
              <div className={styles.countdownTime}>{getTimeUntilArrival().seconds}</div>
              <div className={styles.countdownMeasure}>Seconds</div>
            </div>
          </div>

        </div>
        <div className={styles.widgetRight}>
          <div className={styles.recommendationsTitle}>Recommendations</div>
          <div className={styles.recommendationsContent}>
            {
              recommendations.map((r)=>{
                return(
                    <div className={styles.recommendation}>
                      <div className={styles.recommendationName}>{r.from}</div>
                      <div className={styles.recommendationType}>{r.type}</div>
                      <div className={styles.recommendationMessage}>{r.message}</div>
                      <div className={styles.recommendationUpvotes}><img src={thumb} className={styles.upvotesIcon} /> {r.upvotes} Upvotes</div>
                    </div>
                )
              })
            }
            <div className={styles.centerButton}>
              <div className={styles.recommendationButton}>Recommend Something To Gareth <img src={book} className={styles.bookIcon} /></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )

}

export default App;
