import React from 'react'
import { Routes, Route, BrowserRouter} from 'react-router-dom'
import Home from './pages/Home'
import Nie from './pages/Nie'
import NYC from './pages/NYC'
import Privacy from './pages/Privacy'

export const App = () => {

  return (
          <BrowserRouter>
              <Routes>
                <Route  path='/' element={<Home />} />
                <Route  path='/nie' element={<Nie />} />
                <Route  path='/countdown' element={<NYC />} />
                <Route  path='/privacy' element={<Privacy />} />
              </Routes>
          </BrowserRouter>
  )
}


export default App;
