
import { motion } from "framer-motion"
import { useState, useEffect } from 'react'
import Select from "react-select";
import { ClipLoader } from "react-spinners"
import styles from './css/home.module.scss'

import './App.css'
import gareth from './assets/gareth-nyc.png'
import nyc from './assets/nyc.jpg'
import thumb from './assets/thumb.png'
import book from './assets/book.png'


function App() {
    
  useEffect(() => {
  }, [])

 

  return(
    <div>
      <h1>Privacy Policy for Simply Nomad</h1>
    <p><strong>Effective Date:</strong> 12/01/2025</p>
    <p>At Simply Nomad, we value the privacy and security of your personal information. This Privacy Policy outlines how we collect, use, protect, and share your information when you use our services through our app or website. By using Simply Nomad, you agree to the collection and use of information in accordance with this policy.</p>

    <h2>1. Information We Collect</h2>
    <p>We collect various types of information to provide and improve our services. The information you provide to us can include:</p>
    <p><strong>a. Personal Information:</strong></p>
    <ul>
        <li><strong>Name:</strong> To create and personalize your account.</li>
        <li><strong>Email Address:</strong> To send you notifications, updates, and communication related to your account.</li>
        <li><strong>Password:</strong> To secure your account.</li>
        <li><strong>Location:</strong> To offer location-specific recommendations, connect you with nearby users, and improve the relevance of services.</li>
        <li><strong>Payment Details:</strong> Only necessary for booking tax appointments or any other paid services. We never store your payment details—these are processed securely through third-party payment gateways.</li>
    </ul>
    <p><strong>b. Profile Information:</strong></p>
    <ul>
        <li><strong>Hobbies and Interests:</strong> To personalize your experience and connect you with other users who share similar interests.</li>
        <li><strong>Forum Activity:</strong> Your posts, comments, and interactions in forums and discussions on our platform.</li>
    </ul>
    <p><strong>c. Usage Data:</strong></p>
    <ul>
        <li>Information about how you use the app, such as device information, app interactions, and error logs. This helps us understand usage patterns and improve the app’s functionality.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use the collected information for various purposes, including:</p>
    <ul>
        <li><strong>Account Creation and Management:</strong> To allow you to sign up, log in, and manage your Simply Nomad profile.</li>
        <li><strong>Personalization:</strong> To customize your experience by suggesting relevant content, people, and services based on your interests and location.</li>
        <li><strong>Community Interaction:</strong> To enable you to interact in forums, post content, and comment on others' posts.</li>
        <li><strong>Insurance Referrals:</strong> To provide you with recommendations and referrals for insurance services.</li>
        <li><strong>Tax Appointment Booking:</strong> To help you schedule and manage tax-related appointments.</li>
        <li><strong>Communication:</strong> To send you updates, promotions, and customer support messages.</li>
    </ul>

    <h2>3. How We Protect Your Information</h2>
    <p>We take security seriously and implement a range of measures to protect your personal data, including:</p>
    <ul>
        <li><strong>Encryption:</strong> Your password and sensitive data are encrypted to ensure your information is secure when transmitted.</li>
        <li><strong>Secure Storage:</strong> Payment details are processed securely through third-party payment gateways. We do not store your payment information.</li>
        <li><strong>Access Control:</strong> Only authorized personnel have access to your data to manage and maintain the services.</li>
        <li><strong>Data Minimization:</strong> We only collect the information necessary for providing our services and will not retain it longer than needed.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:</p>
    <ul>
        <li><strong>Service Providers:</strong> We may share your data with trusted third-party service providers who assist us in operating our platform, such as payment processors and communication tools.</li>
        <li><strong>Insurance Referrals:</strong> When you request an insurance referral, your information may be shared with trusted partners who provide the insurance services.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to a valid legal request (e.g., subpoenas, court orders, or government inquiries).</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred to the new owner, but we will notify you beforehand.</li>
    </ul>

    <h2>5. Your Rights and Choices</h2>
    <p>You have certain rights over your data, including:</p>
    <ul>
        <li><strong>Access:</strong> You can request access to the personal information we hold about you.</li>
        <li><strong>Correction:</strong> If you believe any information we hold about you is incorrect, you can request it be updated.</li>
        <li><strong>Deletion:</strong> You can request to delete your account or any specific personal data we have stored, subject to any legal obligations.</li>
        <li><strong>Opt-Out:</strong> You can opt out of marketing communications by following the unsubscribe instructions in the emails we send.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="mailto:simplynomadinfo@gmail.com">simplynomadinfo@gmail.com</a>.</p>

    <h2>6. Cookies and Tracking Technologies</h2>
    <p>Simply Nomad uses cookies and similar tracking technologies to enhance your experience on our platform. These technologies help us understand your preferences, analyze usage, and personalize content. You can manage or disable cookies in your device settings, but this may affect the functionality of the app.</p>

    <h2>7. Third-Party Links</h2>
    <p>Our app may contain links to third-party websites or services that are not controlled by Simply Nomad. We are not responsible for the privacy practices of these third-party sites, and we encourage you to review their privacy policies before sharing any personal information.</p>

    <h2>8. Children’s Privacy</h2>
    <p>Simply Nomad is not intended for use by children under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected information from a child, we will take steps to delete that information.</p>

    <h2>9. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through a notice on our app. The updated policy will be posted with an updated effective date.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
    <p><strong>Simply Nomad Support</strong><br/>
    Email: <a href="mailto:simplynomadinfo@gmail.com">simplynomadinfo@gmail.com</a><br/>
    Website: <a href="https://simplynomad.co">https://simplynomad.co</a>
    </p>

    <p>By using Simply Nomad, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your information as outlined.</p>

    </div>
  )

}

export default App;
