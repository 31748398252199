
import { motion, AnimatePresence } from "framer-motion"
import { useState, useEffect, useRef } from 'react'
import Select from "react-select";
import { ClipLoader } from "react-spinners"
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import Swal from 'sweetalert2'
import emailjs from '@emailjs/browser'
import styles from './css/home.module.scss'

import './App.css'
import logo from './assets/logo.png'
import paul from './assets/paul.png'
import amy from './assets/amy.png'
import evan from './assets/evan.png'
import clients from './assets/clients.png'
import workIcon1 from './assets/work-icon-1.png'
import workIcon2 from './assets/work-icon-2.png'
import workIcon4 from './assets/work-icon-4.png'
import workIcon5 from './assets/work-icon-5.png'
import headshot3 from './assets/headshot-3.png'
import spain from './assets/spain.png'
import triangle from './assets/footer-triangle.png'
import platformMockup from './assets/platform-mockup.png'
import scroll from './assets/scroll.gif'
import closeIcon from './assets/close.svg'
import check from './assets/check.png'
import { TextInput } from './components/TextInput'
import { Checkbox } from './components/Checkbox'
import { loadStripe } from "@stripe/stripe-js"
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js"
import SplitForm from './components/CardForm'
import requestNIEPublic from "./utils/requestNIE";

const SERVICE_OPTION_IDS = [
  'NIE/ TIE',
  'NIE/ TIE Renewal',
  'Driving licence change',
  'Social Security Number',
  'Empadronamiento',
  'Bank Account'
]

const SERVICE_OPTION_PRICES = {
  'NIE/ TIE':119,
  'NIE/ TIE Renewal':75,
  'Driving licence change':119,
  'Social Security Number':60,
  'Empadronamiento':75,
  'Bank Account':50
}

const SITUATION_IDS = [
  'Work',
  'Study',
  'Start a Business',
  'Join a family member',
  'Buy a property',
  'Other'
]

const CITIES = [
  'Barcelona',
  'Madrid',
  'Valencia'
]

let lastClick = 0


const WorkCard = ({title,subtitle,icon,id})=>{
  return(
    <div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.5 }}
    id={id} className="workCard">
        <img src={icon} className="workCardIcon" />
        <div className="workCardText">
          <div className="workCardTitle">{title}</div>
          <div className="workCardSubtitle">{subtitle}</div>
        </div>
    </div>
  )
}

const Dropdown = ({title,options, onChange})=>{

  return(
    <div className="dropdown">
        <div className="dropdownTitle">{title}</div>
        <select onChange={(event)=>{onChange(event.target.value)}}  className="dropdownInput">
            {
              options.map((option)=> <option key={option} value={option}>{option}</option>)
            }
        </select>
    </div>
  )
}

            

const Country = ({title,icon})=>{
  return(
    <div className="country">
        <img src={icon} className="countryIcon" />
        <div className="countryTitle">{title}</div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}

const NIEErrors = (form)=>{
  if(!form.requirement.length) return 'Please choose a service you require.'
  if(!form.country.length) return 'Please choose a country you have a passport from.'
  if(!form.email.length || !form.email.includes('@') || !form.email.includes('.')) return 'Please enter a valid email.'
  if(!form.name.length) return 'Please enter your name'

  return null
}

const PaymentWindow = ({ isVisible, onClose }) => {

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [stripeProm, setStripeProm] = useState(null)
  const [payload, setPayload] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const cardRef = useRef()

  useEffect(async() => {
    const stripePromise = await loadStripe('pk_test_51NwofrJeFMcJLFJXaaTYs9rAgR2DTClk2lcMlK8ThHwIs0326WtkQIyFCVx8LZrdOhv1enRndhprAUDCHR5QbKou00rKVlKuEA');
    setStripeProm({...stripePromise})
  },[])

  const submitPayment = async()=>{
    if(lastClick && ((new Date().getTime() - lastClick) < 2000)) return

    setLoading(true)
    lastClick = new Date().getTime();



      const payload = await cardRef.current.handleSubmit()

      if(payload.error){
        setLoading(false)
        setError(payload.error.message)
        return;
      }

    let res = await requestNIEPublic({
      ...isVisible,
      paymentMethod:payload.paymentMethod.id
    })

    console.log(res)

    if(res.data?.success){
      setSubmitted(true)
    }else if(res.data?.actionRequired){
      setLoading(false)
      const { paymentIntent, error } = await stripeProm.confirmCardPayment(
          res.data.clientSecret
        )

        if (error) return alert("Error in payment, please try again later");
        if (paymentIntent.status === "succeeded"){
          setLoading(true)
          const confirmPaymentData = await requestNIEPublic({
            check:true,
            paymentIntentId:res.data?.paymentIntentId
          })
          if(confirmPaymentData.data.success){
            setSubmitted(true)
          }else{
            alert("Error with payment, please try again.")
          }
        }


    }else{
      console.log(res.payload)
      setError(res.payload?.error || 'An unknown error occurred. Please try again.')
    }
    setLoading(false)
  }


  return(
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="documentsWindowContainer"
      >
        <div className="documentsWindow">
          <div className="documentsWindowTitle"><Emoji name="compass" className={styles.emojiMarginTop} width={18} /> Pay & Submit Application</div>
          <img src={closeIcon} onClick={(e)=>{ onClose() }} className="closeIcon" />
          <div className="documentsWindowContent">
                    {(submitted)?
                    <div className={styles.submissionText}>
                      <img src={check} className={styles.checkIcon} /><br/><br/>
                      Congratulations, you're application was submitted!<br/><br/>
                      Our partners will be in touch soon. <br/><br/>
                    </div>
                    :<div className={styles.center}>
                      <Elements stripe={stripeProm}>
                        <ElementsConsumer>
                          {({elements, stripe}) => (
                            <SplitForm context={this} setPayload={async(v)=>{
                              setPayload({...v});
                            }} ref={cardRef} elements={elements} stripe={stripe} />
                          )}
                        </ElementsConsumer>
                      </Elements>

                      <table className={styles.benefitsList}>
                        <tr className={styles.tr}>
                          <td className={styles.td}>
                            <b>Total Admin Fee (Due Now):</b>
                          </td>
                          <td className={styles.td}><b>
                            €
                          {
                            isVisible.requirement.reduce((total, r)=>{
                              console.log('gliff')
                              console.log(r)
                              return (total + SERVICE_OPTION_PRICES[r])
                            },0) * 0.25
                          }
                          </b></td>
                          </tr>
                        
                        <tr className={styles.tr}>
                          <td className={styles.td}>
                            Partner Fee (Not Due Until Later):
                          </td> 
                          <td className={styles.td}>€{
                            isVisible.requirement.reduce((total, r)=>{
                              return (total + SERVICE_OPTION_PRICES[r])
                            },0)
                          }</td>
                          </tr>
                      </table>
          
            <div className={styles.error}>{error}</div>
            <br/>
            <div className={styles.center}>
              {(loading)?
              <ClipLoader size={50} color={'#5603fc'} />
              :
              <div className={styles.saveButton} onClick={(e)=>{ submitPayment() }}>
              Pay & Submit <Emoji name="compass" className={styles.emojiMarginTop} width={18} />
              </div>
              }
            </div>
            </div>
            }
          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
  )
}

const DocumentsWindow = ({ isVisible, onClose, setFormValues }) => {


  const [workType, setWorkType] = useState('I Am A Freelancer')
  const [income, setIncome] = useState(10000)
  const [country, setCountry] = useState(['ES'])
  const [employerCountry, setEmployerCountry] = useState(null)
  const [countries, setCountries] = useState([])
  const [message, setMessage] = useState('')
  const [situation, setSituation] = useState(SITUATION_IDS[0])
  const [city, setCity] = useState(CITIES[0])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [serviceOptions, setServiceOptions] = useState(SERVICE_OPTION_IDS.map((s)=>{ return {label:s, checked:0} }))

  const openPayment = ()=>{

    const formData = {
      name,
      email,
      city,
      situation,
      country:country?.map((c)=>{
        return countries.filter((option)=>{ return option.value === c })[0]?.label
      }).join(', '),
      message,
      requirement:serviceOptions.filter((s)=>{ return s.checked }).map((s)=>{ return s.label })
    }

    if(NIEErrors(formData)) return setError(NIEErrors(formData))
     setFormValues(formData)
     onClose()
  }

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup?estimatedIncome='+income+'&country='+country.map((c)=>{ return c.value }).join(',')+'&workType='+workType+'employerCountry='+employerCountry.value
  }

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        setCountry([data.userSelectValue])
        setEmployerCountry(data.userSelectValue);
      })
  }, [])

  return(
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="documentsWindowContainer"
      >
        <div className="documentsWindow">
          <div className="documentsWindowTitle">📁 Required Documents & Tax Strategy</div>
          <img src={closeIcon} onClick={(e)=>{onClose()}} className="closeIcon" />
          <div className="documentsWindowContent">
          <input type="text" className="countryInput" style={{paddingLeft:'5px',paddingTop:'5px',paddingBottom:'5px'}} placeholder='Name' onChange={(e)=>{ setName(e.target.value) }} value={name} />
          <br/><br/>
          <input type="email" className="countryInput" style={{paddingLeft:'5px',paddingTop:'5px',paddingBottom:'5px'}} placeholder='Email' onChange={(e)=>{ setEmail(e.target.value) }} value={email} />
           
           
          <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country.map((c)=>{
              return countries.filter((option)=>{ return option.value === c })[0]
            })} onChange={(option)=>{ setCountry(option.map((o)=>{ return o.value })) }} />
            <br/><br/>
            <div className="radioQuestion">Please Select What You Need Below. You Will Be Charged Accordingly.</div>
            <br/>
            <div className={styles.options}>
              {
                serviceOptions.map((s,si)=>{
                  return <Checkbox label={s.label} checked={s.checked} onClick={(e)=>{ 
                    let tmp = [...serviceOptions]
                    tmp[si].checked = !tmp[si].checked
                    setServiceOptions(tmp)
                  }} />
                })
              }
            </div>
            <br/><br/>
            <div className="radioQuestion">What Do You Need This For?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={SITUATION_IDS} value={situation} onChange={(value)=>{ setSituation(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">What City Do You Want Your Appointment In?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={CITIES} value={city} onChange={(value)=>{ setCity(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">Additional Comments & Queries</div>
            <div className={styles.center}>
              <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} placeholder={'Comments...'} className={styles.query} />
            </div>

            <div className={styles.error}>{error}</div>

            <br/>
            
            <div className={styles.center}>
              {(loading)?
              <ClipLoader size={50} color={'#5603fc'} />
              :
              <div className={styles.saveButton} onClick={(e)=>{ openPayment() }}>
              Next Step <Emoji name="compass" className={styles.emojiMarginTop} width={18} />
              </div>
              }
            </div>

          </div>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
  )
}

const EmailSentPopup = ({ isVisible, onClose }) => {

  return(
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="documentsWindowContainer"
      >
        <div className="documentsWindow">
          <img src={closeIcon} onClick={(e)=>{onClose()}} className="closeIcon" />
          
        </div>
      </motion.div>
    )}
  </AnimatePresence>
  )
}

const DocumentsWidget = ({setFormValues}) => {

  const [workType, setWorkType] = useState('I Am A Freelancer')
  const [income, setIncome] = useState(10000)
  const [country, setCountry] = useState(['ES'])
  const [message, setMessage] = useState('')
  const [situation, setSituation] = useState(SITUATION_IDS[0])
  const [city, setCity] = useState(CITIES[0])
  const [loading, setLoading] = useState(false)
  const [nieOpen, setNieOpen] = useState(false)
  const [employerCountry, setEmployerCountry] = useState(null)
  const [countries, setCountries] = useState([])
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [serviceOptions, setServiceOptions] = useState(SERVICE_OPTION_IDS.map((s)=>{ return {label:s, checked:0} }))

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup?estimatedIncome='+income+'&country='+country.map((c)=>{ return c.value }).join(',')+'&workType='+workType+'employerCountry='+employerCountry.value
  }

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
    .then((response) => response.json())
    .then((data) => {
      setCountries(data.countries)
      setCountry([data.userSelectValue])
      setEmployerCountry(data.userSelectValue);
    })
  }, [])

  const openPayment = ()=>{
    const formData = {
      name,
      email,
      city,
      situation,
      country:country?.map((c)=>{
        return countries.filter((option)=>{ return option.value === c })[0]?.label
      }).join(', '),
      message,
      requirement:serviceOptions.filter((s)=>{ return s.checked }).map((s)=>{ return s.label })
    }

    if(NIEErrors(formData)) return setError(NIEErrors(formData))
    setFormValues(formData)
  }

  return(
        <div className="documentsWidget">
          <div className="documentsWindowTitle"><Emoji name="compass" className={styles.emoji} width={22} /> Book An Appointment</div>
          <div className="documentsWindowContent">
          <input type="text" className="formText" placeholder='Name' onChange={(e)=>{ setName(e.target.value) }} value={name} />
          <br/><br/>
          <input type="text" className="formText" placeholder='Email' onChange={(e)=>{ setEmail(e.target.value) }} value={email} />
           
           
          <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country.map((c)=>{
              return countries.filter((option)=>{ return option.value === c })[0]
            })} onChange={(option)=>{ setCountry(option.map((o)=>{ return o.value })) }} />
            <br/><br/>
            <div className="radioQuestion">Please Select What You Need Below. You Will Be Charged Accordingly.</div>
            <br/>
            <div className={styles.options}>
              {
                serviceOptions.map((s,si)=>{
                  return <Checkbox label={s.label} checked={s.checked} onClick={(e)=>{ 
                    let tmp = [...serviceOptions]
                    tmp[si].checked = !tmp[si].checked
                    setServiceOptions(tmp)
                  }} />
                })
              }
            </div>
            <br/><br/>
            <div className="radioQuestion">What Do You Need This For?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={SITUATION_IDS} value={situation} onChange={(value)=>{ setSituation(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">What City Do You Want Your Appointment In?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={CITIES} value={city} onChange={(value)=>{ setCity(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">Additional Comments & Queries</div>
            <div className={styles.center}>
              <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} placeholder={'Comments...'} className={styles.query} />
            </div>

            <div className={styles.error}>{error}</div>
            <br/>
                    
            <div className={styles.submit}>
              {(loading)?
              <ClipLoader size={50} color={'#5603fc'} />
              :
              <div className={styles.saveButton} onClick={(e)=>{ openPayment() }}>
              Send Application <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
              </div>
              }
            </div>
          </div>
        </div>
  )
}


const Review = ({name, image, text, location}) => {

  return(
        <div className="review">
            <img src={image} className="reviewImage" />
            <div className="reviewName">{name}</div>
            <div className="reviewLocation">{location}</div>
            <div className="reviewStars">
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
              <Emoji name="star" className="emoji" width={32} />
            </div>
            <div className="reviewText">{text}</div>
        </div>
  )
}



function App() {

  const [documentsWindowOpen, setDocumentsWindowOpen] = useState(false)
  const [formEmail, setFormEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const [formValues, setFormValues] = useState(null)

  const goToSignUp = ()=>{
    document.location = 'https://app.simplynomad.co/signup'
  }

  const sendEmail = async(e)=>{
    e.preventDefault()
    
    await setLoading(true)

    if(!formEmail.includes('@') || !formEmail.includes('.')){
      Swal.fire(
        'Message Not Sent!',
        'Please Enter A Valid Email To Start.',
        'error'
      )

      setLoading(false)
    }

    emailjs.send('service_91jtlma', 'simply_nomad', {
      name: '',
      email: formEmail,
      message: '',
      income: '',
      type: '',
      passports: ''
    }, 'h8beUKjFjcEy8tO0m')
      .then((result) => {
            Swal.fire(
          'Your Message Was Sent!',
          'We will get back to you shortly with access to our platform.',
          'success'
        )
        setLoading(false)
        setFormEmail('')
      }, (error) => {
        Swal.fire(
          'Message Not Sent!',
          'Please Try Again',
          'error'
        )
        console.log(error)
        setLoading(false)
      });
  }


  
  return (
    <EmojiProvider data={emojiData}>
    <div className="app">
      <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="header">
        <div className="headerLogo">
          <img src={logo} className="headerLogoImage" />
          SimplyNomad
        </div>

        <div className="headerButtons">
          <div className="headerMenuItem">NIE & Tax</div>
          <div className="headerMenuItem">Insurance</div>
          <div className="headerMenuItem">Nomad Events</div>
          <div className="headerMenuItem">Affiliate Program</div>
          <div onClick={(e)=>{ setDocumentsWindowOpen(true) }} className="headerSignupButton">Instant NIE Booking <Emoji name="euro-banknote" className="emoji" width={18} /></div>
        </div>
      </motion.div>

      <div className="landingContainer">
        <div className="landingContainerLeft">
          <div className="registerCard">
            <div className="registerCardTitle">Moving To Spain As A Digital Nomad? Get Your NIE / TIE Quickly!</div>
            <div className="registerCardSubtitle">
              Instantly know what you need to get tax-registered as a nomad in Spain. Our team can remove all complications and our 
              convenient platform makes filing and management hassle-free.
              <br/><br/>
            </div>
            <input type="text" className="registerCardEmail" placeholder='Email' onChange={(e)=>{ setFormEmail(e.target.value) }} value={formEmail} />
            <br/><br/> 
            {
              (loading)?
              <ClipLoader  size={30} color={"#3600D7"} />
              :
              <div className="formSignupButton" onClick={(e)=>{ goToSignUp() }}>
                Get Your Appointment <Emoji name="rocket" className="emoji" width={18} />
              </div>
            }

              <div className="trustedBy">
                <img src={clients} className="trustedByClients" />
                <div className="trustedByTextContainer">
                  <div className="trustedByStars">
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                    <Emoji name="star" className="emoji" width={16} />
                  </div>
                  <div className="trustedByText">
                    Trusted By Over 300 Digital Nomads Living In Spain.
                  </div>
                </div>
              </div>

          </div>
        </div>
        <img src={scroll} className="scrollGif" />
        <div className="landingContainerRight">
          <table className={styles.benefitsList}>
                      <tr className={styles.tr}>
                          <td className={styles.td}>
                            <img src={check} className={styles.checkIconSmall} /> 
                          </td>
                          <td className={styles.td}>No More Waiting Months For A NIE Appointment!</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>Get Your NIE From Just €149</td>
                      </tr>
                    
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>A Partner Will Accompany You To Your NIE Appointment On The Day</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Our Partners Book Your NIE Appointment For You</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>Our Partners Assist With Your Documentation</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Get Support On Any Legal Queries</td>
                      </tr>
                  </table>
        </div>
      </div>

      <br/><br/><br/><br/>
      <div className="mobileOnly">
      <table className={styles.benefitsList}>
                      <tr className={styles.tr}>
                          <td className={styles.td}>
                            <img src={check} className={styles.checkIconSmall} /> 
                          </td>
                          <td className={styles.td}>No More Waiting Months For A NIE Appointment!</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>Get Your NIE From Just €149</td>
                      </tr>
                    
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>A Partner Will Accompany You To Your NIE Appointment On The Day</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Our Partners Book Your NIE Appointment For You</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>Our Partners Assist With Your Documentation</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Get Support On Any Legal Queries</td>
                      </tr>
                  </table>
        </div>

      <div className="howItWorks" style={{paddingBottom:'0px',marginTop:'40px'}}>
        <div className="separator"></div>
        <div className="sectionTitle">How Can We Help You?</div>
        <br/><br/>
        <div className="sectionColumns">
          <div className="sectionColumnLeft">
            <WorkCard icon={workIcon1} id="workCard1" title="Work With Our Reliable Partners" subtitle="We have trusted partners in cities in Spain, specialising in getting you your NIE quickly and effectively." />
            <WorkCard icon={workIcon2} id="workCard2" title="Our Partners Help Prepare Your Documents" subtitle="Get friendly advice from people you can trust." />
            <WorkCard icon={workIcon5} id="workCard3" title="Our Partners Book Your Appointment" subtitle="Our representatives find and book a NIE appointment in your chosen city." />
            <WorkCard icon={workIcon4} id="workCard4" title="A Partner Will Accompany You To Your Appointment On The Day" subtitle="With a long-standing reputation with the Spanish police, this helps everything run smoothly." />
          </div>
          <div className="sectionColumnRight">
            <img src={platformMockup} className="mockup" />
            <br/><br/><br/>
            <div className="formSignupButton" onClick={(e)=>{ setDocumentsWindowOpen(true) }}>
              Book An Appointment Now <Emoji name="rocket" className="emoji" width={18} />
            </div>
          </div>
        </div>
      </div>
      <br/><br/>
      <div className="howItWorks">
        <div className="separator"></div>
        <div className="sectionTitle">Amazing Local Accountants In Spain 🇪🇸</div>
        <div className="sectionSubtitle">We work with nomads and freelancers coming from <b>anywhere</b>, looking to work and live in Spain.</div>

        <div className="sectionColumns">
          <div className="sectionColumnLeftCountry">
              <div className="countries">
                <Country icon={spain} title={''} />
                <br/><br/><br/>
                <div className="headshotsContainer">
                  <img src={headshot3} className="headshot" />
                  <div className="accountantQuote">
                    "Since working as a Simply Nomad partner, we have received 2x the enquiries we usually get per month."<br/>
                    
                  </div>
                </div>
              </div>
          </div>
          <div className="sectionColumnRightCountry">
            <DocumentsWidget setFormValues={setFormValues} />
          </div>
        </div>
      </div>


      <div className="howItWorks">
        <div className="separator"></div>
        <div className="sectionTitle">Don't Just Take It From Us</div>
        <div className="reviews">
          <Review name={"Evan Power"} image={evan} location={'Valencia'} text={"I found it extremely difficult to find reliable accountants and a straightforward platform for nomads in Spain. Simply Nomad made the tax registration process simple. They set me up in just 1 week."} />
          <Review name={"Paul Kelly"} image={paul} location={'Barcelona'} text={"Simply Nomad gave me all the information I needed to start working remotely once I arrived in Spain. Previously, I had been looking for 5 months for a suitable accountant. I would definitely recommend it."} />
          <Review name={"Amy Williams"} image={amy} location={'Sevilla'} text={"The setup was quick and easy and I could send my accountant documents very easily. I will be continuing to use this platform."} />
        </div>
      </div>

      <div className="howItWorks">
        <div className="separator"></div>
        <img src={triangle} className="bottomTriangle"></img>
        <div className="footerCard">
            <div className="registerCardTitle">Ready To Book?</div>
            <div className="registerCardSubtitle">
              Get Your NIE Quickly With US Today. 
              <br/><br/>
            </div>
            <input type="text" className="registerCardEmail" placeholder='Email' onChange={(e)=>{ setFormEmail(e.target.value) }} value={formEmail} />
            <br/><br/> 
            {
              (loading)?
              <ClipLoader  size={30} color={"#3600D7"} />
              :
              <div className="formSignupButton" onClick={(e)=>{ goToSignUp() }}>
                Next Steps  <Emoji name="rocket" className="emoji" width={18} />
              </div>
            }

          </div>
      </div>

      <div className="footer">
        <div className="footerMenu">
          <a href="./"><Emoji name="house" className="emoji" width={32} /> Home</a><br/><br/>
          <a href="https://app.simplynomad.co/signup"><Emoji name="rocket" className="emoji" width={32} />Apply Now</a><br/><br/>
          <a href=""><Emoji name="page-facing-up" className="emoji" width={32} /> Terms & Conditions</a>
        </div>
        <div className="footerCopyright">
          © Copyright Simply Nomad Co. 2024 
        </div>
      </div>
      <DocumentsWindow isVisible={documentsWindowOpen} setFormValues={setFormValues} onClose={()=>{setDocumentsWindowOpen(false)}} />
      <PaymentWindow isVisible={formValues}  onClose={()=>{ setFormValues(null) }} />
      <EmailSentPopup />
    </div>
    </EmojiProvider>
  );
}

export default App;
