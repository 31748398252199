import React, { useMemo } from "react";
import {
  ElementsConsumer,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

import './cardForm.css';
import { transform } from "framer-motion";




class SplitForm extends React.Component {


  constructor(props){
    super(props);

    this.props = props;
    this.stripe = this.props.stripe;
    this.elements = this.props.elements;

    this.options = {
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Comfortaa, sans-serif",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }

  }

  handleSubmit = async()=>{

    if (!this.stripe || !this.elements) {
      //this.props.context.setState({payload:{error:"NOT SET UP YET."}});
      //return;
    }

    console.log(this.stripe)

    const payload = await this.stripe.createPaymentMethod({
      type: "card",
      card: this.elements.getElement(CardNumberElement)
    });


    return payload;

  };

  render(){
    return (
      <form style={{alignItems:'center',textAlign:'center'}}>
        <label>
          Card number <br/>
          <div style={{display:'inline-block',alignSelf:'center', width:'400px', maxWidth:'90%'}}>
            <CardNumberElement
              options={this.options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
          <br/>
        </label>
        <label>
          Expiration Date<br/>
          <div style={{display:'inline-block',alignSelf:'center', width:'400px', maxWidth:'90%'}}>
            <CardExpiryElement
              options={this.options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
          <br/>
        </label>
        <label>
          CVC<br/>
          <div style={{display:'inline-block',alignSelf:'center', width:'400px', maxWidth:'90%'}}>
            <CardCvcElement
              options={this.options}
              onReady={() => {
                console.log("CardNumberElement [ready]");
              }}
              onChange={event => {
                console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                console.log("CardNumberElement [focus]");
              }}
            />
          </div>
        </label>
      </form>
    );
  }

};

export default SplitForm;
